<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">

                    <!-- Invoice Logo-->
                    <div class="clearfix">
                        <div class="float-left mb-3 mt-2">
                            <img alt="" height="45" src="../../../../assets/images/logo-dark.png">
                        </div>
                    </div>

                    <!-- Invoice Detail-->
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="float-left mt-3">
                                <img :src="qrUrl" alt="" v-if="qrUrl">
                            </div>

                        </div><!-- end col -->
                        <div class="col-sm-4 offset-sm-2">
                            <div class="mt-3 float-sm-right">
                                <p class="font-13" v-if="consignment"><strong>Receive Date: </strong> &nbsp;&nbsp;&nbsp;
                                    {{consignment.created | date}}</p>
                                <p class="font-13"><strong>Consignment ID: </strong> <span>{{consignmentid}}</span>
                                </p>
                                <p class="font-13" v-if="truck_registration_no"><strong>Truck Registration
                                    Number: </strong> <span>{{truck_registration_no[2].fact.value}}</span>
                                </p>
                                <p class="font-13" v-if="origin"><strong>Origin: </strong> <span>{{origin[2].fact.value}}</span>
                                </p>
                            </div>
                        </div><!-- end col -->
                    </div>
                    <!-- end row -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="clearfix pt-3 table-responsive">
                                <table class="table table-bordered mb-0">
                                    <thead>
                                    <tr>
                                        <th>Step</th>
                                        <th>Attester</th>
                                        <th>Physical Signature</th>
                                        <th>Completed</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(step, index) in definition_steps">
                                        <tr v-for="(attester, index2) in step.attestors">
                                            <td :rowspan="step.attestors.length" v-if="index2===0">{{step.name}}</td>
                                            <td>{{attester.short_name}}</td>
                                            <td></td>
                                            <td :rowspan="step.attestors.length" v-if="index2===0"><div class="checkbox text-muted"></div></td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="d-print-none mt-4">
                        <div class="text-right">
                            <a class="btn btn-primary" href="javascript:window.print()"><i class="mdi mdi-printer"></i>
                                Print</a>
                            <a @click="$router.go(-1)" class="btn btn-danger ml-1" href="javascript: void(0);"><i
                                    class="mdi mdi-close-box"></i>&nbsp;Close</a>
                        </div>
                    </div>
                    <!-- end buttons -->

                </div> <!-- end card-body-->
            </div> <!-- end card -->
        </div> <!-- end col-->
    </div>
</template>

<script>
    import QRCode from "qrcode";
    import {mapGetters} from "vuex";
    import EventBus from "../../../../event-bus";

    export default {
        name: "PrintConsignment",
        props: ['consignmentid'],
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                definition_steps: [],
                consignment: null,
                qrUrl: null,
                first_step: null,
                truck_registration_no: null,
                origin: null,
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                EventBus.$emit('openLoader');
                await Promise.all([
                    await this.getProcessDefinitionSteps(),
                    await this.getProcess(),
                    await this.generateQr(),
                ]).then(res => {
                    EventBus.$emit('closeLoader');
                }).catch(e => {
                    EventBus.$emit('closeLoader');
                })
            },
            async getProcessDefinitionSteps() {
                try {
                    let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/process_definition_steps`, {
                        params: {
                            page: 0,
                            per_page: 100,
                            process_type: 'Consignment'
                        }
                    });
                    this.definition_steps = data.process_definition_steps;
                } catch (e) {
                    console.error(e)
                }
            },
            async getProcess() {
                try {
                    let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                        params: {
                            page: 0,
                            per_page: 1,
                            process_type: 'Consignment',
                            search: this.consignmentid,
                            fetch: {
                                attributes: [{
                                    attribute_name: "Truck No.",
                                    attribute_step: 0
                                },{
                                    attribute_name: "Origin (Mukam Area)",
                                    attribute_step: 0
                                }]
                            }
                        }
                    });
                    this.consignment = data.processes[0];
                    if(this.consignment.attributes.length>0){
                        this.truck_registration_no = this.consignment.attributes.find(ca=> ca[2].name === 'Truck No.');
                        this.origin = this.consignment.attributes.find(ca=> ca[2].name === 'Origin (Mukam Area)');
                    }
                } catch (e) {

                }
            },
            async generateQr() {
                let qr = `${this.$SITE_URI}/consignments/${this.consignmentid}`;

                this.qrUrl = await QRCode.toDataURL(qr, {
                    width: 200,
                    type: 'svg',
                    color: {
                        dark: '#000',  // Blue dots
                        light: '#0000' // Transparent background
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .checkbox {
        width:20px;
        height:20px;
        border: 1px solid #adb5bd;
        display: inline-block;
    }
</style>