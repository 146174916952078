import { render, staticRenderFns } from "./PrintConsignment.vue?vue&type=template&id=6195e98b&scoped=true&"
import script from "./PrintConsignment.vue?vue&type=script&lang=js&"
export * from "./PrintConsignment.vue?vue&type=script&lang=js&"
import style0 from "./PrintConsignment.vue?vue&type=style&index=0&id=6195e98b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6195e98b",
  null
  
)

export default component.exports